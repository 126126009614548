import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { User } from "../clients/userClient";
import { NotificationContext } from "./NotificationContext";
import { BadRequestError, ConflictError, NoPermissinError as NoPermissionError, NotFoundError } from "../utils/error/";
import { CreateProjectDTO, CreateTimesheetDTO, Keyinformation, Project, ProjectEmployee, UpdateProjectDTO } from "../clients/project";
import ProjectClient from "../clients/project/ProjectClient";
import ProjectKeyinformationClient from "../clients/project/ProjectKeyinformationClient";

interface ProjectType {
  projects: Project[];
  refreshProject: () => void;
  setProjects: Dispatch<SetStateAction<Project[]>>;
  createProject: (project: CreateProjectDTO) => void;
  submitProjectDelete: (id: string) => void;
  keyinformation?: Keyinformation;
  updateKeyinformation: (keyinformation: Keyinformation) => void;
  updateProject: (project: UpdateProjectDTO) => void;
  currentProject: Project;
  updateContactPersons: (selectedContactPersons: User[]) => void;
  updateProjectEmployees: (selectedProjectEmployees: ProjectEmployee[]) => void;
  setCurrentProject: (project: Project) => void;
  addTimesheetToProject: (timesheet: CreateTimesheetDTO) => void;
  deleteTimesheetFromProject: (timesheetId: string) => void;
}

export const ProjectContext = createContext({} as ProjectType);

const ProjectProvider: React.FC = ({ children }) => {
  const { projectId } = useParams<{ projectId: string }>();
  const { setNotification } = useContext(NotificationContext);
  const [projects, setProjects] = useState<Project[]>([]);
  const [keyinformation, setKeyinformation] = useState<Keyinformation>();
  const [deletedProjectId, setDeleteProjectId] = useState<string>("");
  const [currentProject, setCurrentProject] = useState<Project>({
    id: "",
    title: "",
    customer: "",
    projectStart: "",
    budget: 0,
    status: "",
    statusDetails: "",
    contactPersons: [],
    projectEmployees: [],
    isExternal: false,
  });

  const intl = useIntl();

  const refreshProject = useCallback(() => {
    getProjects();
    // eslint-disable-next-line
  }, []);

  const refreshKeyinformation = useCallback(() => {
    getProjectKeyinformation();
    // eslint-disable-next-line
  }, []);

  // Update Contact Persons
  const updateContactPersons = (selectedContactPersons: User[]) => {
    const updatedProject: UpdateProjectDTO = {
      ...currentProject,
      // UpdateProjectDTO only needs Ids of contactPersons:
      contactPersons: selectedContactPersons.map((contactPerson) => contactPerson.id),
    };

    updateProject(updatedProject);
  };

  // Update Project Employees
  const updateProjectEmployees = (selectedProjectEmployees: ProjectEmployee[]) => {
    const updatedProject: UpdateProjectDTO = {
      ...currentProject,
      // UpdateProjectDTO only needs Ids of contactPersons:
      contactPersons: currentProject.contactPersons.map((contactPerson) => contactPerson.id),
      projectEmployees: selectedProjectEmployees,
    };

    updateProject(updatedProject);
  };

  /**
   * Get all Projects
   */
  const getProjects = () => {
    ProjectClient.getAllProjects()
      .then((projects: Project[]) => {
        setProjects(projects);
      })
      .catch((error) => {
        if (error instanceof BadRequestError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
        }
        if (error instanceof NoPermissionError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.Keyinformation.noPermission.message" }) });
        }
        if (error instanceof NotFoundError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.Keyinformation.NotFound.message" }) });
        }
      });
  };

  /**
   * Get a single Project
   */
  const getProject = () => {
    ProjectClient.getProject(projectId)
      .then((project: Project) => {
        setCurrentProject(project);
      })
      .catch((error) => {
        if (error instanceof BadRequestError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
        }
        if (error instanceof NoPermissionError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.Keyinformation.noPermission.message" }) });
        }
        if (error instanceof NotFoundError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.Keyinformation.NotFound.message" }) });
        }
      });
  };

  /**
   *
   * @param project
   */
  const createProject = (project: CreateProjectDTO) => {
    ProjectClient.createProject(project)
      .then((response: Project) => {
        setProjects([...projects, response]);
        setNotification({ type: "success", message: intl.formatMessage({ id: "ProjectContext.CreateProject.message" }) });
      })
      .catch((error) => {
        if (error instanceof BadRequestError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
        }
        if (error instanceof ConflictError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.ProjectExists.message" }) });
        }
      });
  };

  const updateProject = (project: UpdateProjectDTO) => {
    ProjectClient.updateProject(project)
      .then((response: Project) => {
        setCurrentProject(response);
        setNotification({ type: "success", message: intl.formatMessage({ id: "ProjectContext.UpdateProject.message" }) });
      })
      .catch((error) => {
        if (error instanceof BadRequestError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
        }
        if (error instanceof NoPermissionError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.Keyinformation.noPermission.message" }) });
        }
        if (error instanceof NotFoundError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.Keyinformation.NotFound.message" }) });
        }
      });
  };

  const deleteProject = (projectId: string) => {
    ProjectClient.deleteProject(projectId)
      .then(() => {
        const filteredProjects = projects.filter((project) => {
          return project.id !== deletedProjectId;
        });
        setDeleteProjectId("");
        setProjects([...filteredProjects]);
        setNotification({ type: "success", message: intl.formatMessage({ id: "ProjectContext.ProjectDelete.message" }) });
      })
      .catch((error) => {
        if (error instanceof BadRequestError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
        }
        if (error instanceof NoPermissionError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.Keyinformation.noPermission.message" }) });
        }
        if (error instanceof NotFoundError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.Keyinformation.NotFound.message" }) });
        }
      });
  };

  /**
   * Get Keyinformation
   */
  const getProjectKeyinformation = () => {
    ProjectKeyinformationClient.getProjectKeyinformation(projectId)
      .then((project: Keyinformation) => {
        setKeyinformation(project);
      })
      .catch((error) => {
        if (error instanceof BadRequestError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
        }
        if (error instanceof NoPermissionError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.Keyinformation.noPermission.message" }) });
        }
        if (error instanceof NotFoundError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.Keyinformation.NotFound.message" }) });
        }
      });
  };

  /**
   * Update Keyinformation

   * @param keyinformation
   */
  const updateProjectKeyinformation = (projectId: string, keyinformation: Keyinformation) => {
    ProjectKeyinformationClient.updateProjectKeyinformation(projectId, keyinformation)
      .then((response: Keyinformation) => {
        setKeyinformation(response);
        setNotification({ type: "success", message: intl.formatMessage({ id: "ProjectContext.UpdateProject.message" }) });
      })
      .catch((error) => {
        if (error instanceof BadRequestError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
        }
        if (error instanceof NoPermissionError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.Keyinformation.noPermission.message" }) });
        }
        if (error instanceof NotFoundError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.Keyinformation.NotFound.message" }) });
        }
      });
  };
  /**
   * Adds a timesheet to an existing project
   * @param projectId
   * @param timesheet
   */
  const addTimesheetToProject = (timesheet: CreateTimesheetDTO) => {
    ProjectKeyinformationClient.addTimesheetToProject(currentProject.id, timesheet)
      .then(() => {
        setNotification({ type: "success", message: intl.formatMessage({ id: "ProjectContext.AddTimesheet.message" }) });
        refreshKeyinformation();
      })
      .catch((error) => {
        if (error instanceof BadRequestError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
        }
        if (error instanceof NoPermissionError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.Keyinformation.noPermission.message" }) });
        }
        if (error instanceof NotFoundError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.Keyinformation.NotFound.message" }) });
        }
      });
  };
  /**
   * Deletes a timesheet from a given project
   * @param projectId
   * @param timesheetId
   */
  const deleteTimesheetFromProject = (timesheetId: string) => {
    ProjectKeyinformationClient.deleteTimesheet(currentProject.id, timesheetId)
      .then(() => {
        setNotification({ type: "success", message: intl.formatMessage({ id: "ProjectContext.DeleteTimesheet.message" }) });
        refreshKeyinformation();
      })
      .catch((error) => {
        if (error instanceof BadRequestError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
        }
        if (error instanceof NoPermissionError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.Keyinformation.noPermission.message" }) });
        }
        if (error instanceof NotFoundError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.Keyinformation.NotFound.message" }) });
        }
      });
  };

  const submitProjectDelete = (projectId: string) => {
    setDeleteProjectId(projectId);
    deleteProject(projectId);
  };

  // Update Project Keyinformations
  const updateKeyinformation = (keyinformation: Keyinformation) => {
    updateProjectKeyinformation(projectId, keyinformation);
  };

  useEffect(() => {
    getProjects();

    if (projectId !== undefined) {
      getProject();
      getProjectKeyinformation();
    }
    // eslint-disable-next-line
  }, [setNotification, intl]);

  const data = {
    projects,
    refreshProject,
    setProjects,
    createProject,
    submitProjectDelete,
    keyinformation,
    updateKeyinformation,
    updateProject,
    currentProject,
    updateContactPersons,
    updateProjectEmployees,
    setCurrentProject,
    addTimesheetToProject,
    deleteTimesheetFromProject,
  };

  return <ProjectContext.Provider value={data}>{children}</ProjectContext.Provider>;
};

export default ProjectProvider;
