import {
  Avatar,
  Box,
  Card,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close, Inbox } from "@mui/icons-material";
import { FC, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ProjectContext } from "../../../../contexts/ProjectContext";
import TimesheetDetailModal from "./TimesheetDetailModal";
import { ProjectEmployee, TimesheetDTO } from "../../../../clients/project";
import { grey } from "@mui/material/colors";

interface TimesheetModalProps {
  openDialog: boolean;
  setOpenDialog: (timesheetDialog: boolean) => void;
}

const TimesheetModal: FC<TimesheetModalProps> = ({ openDialog, setOpenDialog }: TimesheetModalProps) => {
  const { currentProject, keyinformation } = useContext(ProjectContext);
  const [timesheetDetailDialog, setTimesheetDetailDialog] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<ProjectEmployee>(currentProject.projectEmployees[0]);
  const [selectedEmployeeTimesheets, setSelectedEmployeeTimesheets] = useState<TimesheetDTO[]>([]);
  const intl = useIntl();

  const onEmployeeClick = (projectEmployee: ProjectEmployee) => {
    setSelectedEmployeeTimesheets(
      keyinformation?.timesheetsDto.filter((timesheet) => timesheet.employee === projectEmployee.userDto.id) ?? [],
    );
    setSelectedEmployee(projectEmployee);
    setTimesheetDetailDialog(true);
  };

  useEffect(() => {
    if (selectedEmployee && keyinformation) {
      setSelectedEmployeeTimesheets(
        keyinformation?.timesheetsDto.filter((timesheet) => timesheet.employee === selectedEmployee.userDto.id) ?? [],
      );
    }
  }, [keyinformation, selectedEmployee]);

  const getLocaleString = (date: Date) => {
    return date.toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" });
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Dialog maxWidth="md" fullWidth open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <FormattedMessage id="ProjectKeyinformation.timesheetDialog.title" />
        <IconButton onClick={() => handleClose()}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <TableContainer component={Card} sx={{ maxHeight: "310px", overflow: "auto" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ backgroundColor: grey[300] }} colSpan={2}>
                  <FormattedMessage id="TimesheetModal.table.projectEmployees" />
                </TableCell>
                <TableCell sx={{ backgroundColor: grey[300] }}>
                  <FormattedMessage id="TimesheetModal.table.timeSpan" />
                </TableCell>
                <TableCell sx={{ backgroundColor: grey[300] }}>
                  <FormattedMessage id="TimesheetModal.table.timesheets" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentProject.projectEmployees.length > 0 ? (
                currentProject.projectEmployees.map((projectEmployee) => (
                  <Tooltip
                    title={intl.formatMessage(
                      { id: "TimesheetModal.details.table.tooltip" },
                      { employee: projectEmployee.userDto.accountInfo.username },
                    )}
                    followCursor
                    key={projectEmployee.userDto.id}
                    enterDelay={500}>
                    <TableRow sx={{ cursor: "pointer" }} hover onClick={() => onEmployeeClick(projectEmployee)}>
                      <TableCell sx={{ py: 0, pr: 0, width: "44px" }}>
                        <Avatar src={projectEmployee.userDto.accountInfo.picture} sx={{ width: "44px", height: "44px" }} />
                      </TableCell>
                      <TableCell sx={{ py: "10px" }}>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography variant="h5">{projectEmployee.userDto.accountInfo.username}</Typography>
                          <Typography variant="body2">{projectEmployee.userDto.accountInfo.email}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                          <Typography variant="body2">{getLocaleString(new Date(projectEmployee.activeFrom as string))}</Typography>
                          &mdash;
                          <Typography variant="body2">
                            {projectEmployee.activeTo ? getLocaleString(new Date(projectEmployee.activeTo as string)) : "heute"}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={
                            keyinformation?.timesheetsDto?.filter((timesheet) => timesheet.employee === projectEmployee.userDto.id).length +
                            " Timesheets"
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </Tooltip>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <Inbox
                        color="action"
                        sx={{ mb: 2, width: 128, height: 128, p: 3, backgroundColor: "#e5e5e5", borderRadius: "50%" }}
                      />
                      <Typography color="action" variant="subtitle1">
                        <FormattedMessage id="TimesheetModal.table.emptyState" />
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <TimesheetDetailModal
        openDialog={timesheetDetailDialog}
        setOpenDialog={setTimesheetDetailDialog}
        projectEmployee={selectedEmployee}
        timesheetsDto={selectedEmployeeTimesheets}
      />
    </Dialog>
  );
};

export default TimesheetModal;
