interface CookieTemplate {
  tokens: { accessToken: string };
}

class CookieParser {
  static getCookie(name: string): string | undefined {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(";").shift();
  }

  static parseCookie(): CookieTemplate {
    const parseCookie = document.cookie.split("; ");
    const result: { [key: string]: string } = {};
    for (const i in parseCookie) {
      const cur = parseCookie[i].split("=");
      result[cur[0]] = cur[1];
    }
    const cookie = this.getCookie("userData");

    // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
    return JSON.parse(decodeURIComponent(cookie!));
  }
}

export default CookieParser;
