import React, { FC, useContext } from "react";
import { Box, Chip, Container, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { useProjectUtils } from "../../../utils/Utils";

const ProjectStateTabPanel: FC = () => {
  const { currentProject } = useContext(ProjectContext);
  const { getProjectStatusColor, getProjectStatusIcon } = useProjectUtils();

  return (
    <Container sx={{ display: "flex", gap: 4 }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="subtitle1">
          <FormattedMessage id="ProjectDetailScreen.tab.projectStatus.title" />
        </Typography>
        <Chip
          icon={getProjectStatusIcon()}
          color={getProjectStatusColor()}
          sx={{ borderRadius: 1, width: "200px", mt: 1 }}
          label={
            <FormattedMessage
              id={`ProjectDetailScreen.tab.projectStatus.${currentProject.status ? currentProject.status.toLowerCase() : "unknown"}`}
            />
          }
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="subtitle1">
          <FormattedMessage id="ProjectDetailScreen.tab.projectStatus.details.header" />
        </Typography>
        <Typography variant="body1" color="GrayText" sx={{ mt: 1 }}>
          {currentProject.statusDetails ?? <FormattedMessage id="ProjectDetailScreen.tab.projectStatus.details.emptyState" />}
        </Typography>
      </Box>
    </Container>
  );
};

export default ProjectStateTabPanel;
