import { Avatar, Box, Container, Typography } from "@mui/material";
import { PersonAdd } from "@mui/icons-material";
import { FC, useContext, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { DataGrid, GridColDef, deDE, GridRowParams } from "@mui/x-data-grid";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import EmployeeCreateModal from "./EmployeeCreateModal";
import { UserContext } from "../../contexts/UserContext";
import { PrivilegeContext } from "../../contexts/PrivilegeContext";
import { PrivilegeEnum } from "../RolesScreen/PrivilegeEnum";

const EmployeeScreen: FC = () => {
  const [pageSize, setPageSize] = useState(10);
  const { users, createUserDialogActive, setCreateUserDialogActive } = useContext(UserContext);
  const { checkForPrivilege } = useContext(PrivilegeContext);
  const history = useHistory();

  const columns: GridColDef[] = [
    {
      field: "picture",
      valueGetter: (params) => params.row.accountInfo.picture,
      headerName: "",
      width: 70,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      renderHeader: () => <></>,
      renderCell: (picture) => (
        <Box
          style={{
            width: 44,
            height: 44,
            borderRadius: "50%",
            overflow: "hidden",
          }}>
          <Avatar sx={{ width: 44, height: 44 }} src={picture.value} />
        </Box>
      ),
    },
    {
      field: "username",
      valueGetter: (params) => params.row.accountInfo.username,
      renderHeader: () => {
        return (
          <Typography variant="caption" color="primary">
            <FormattedMessage id="EmployeeScreen.table.usernameHeader" />
          </Typography>
        );
      },
      width: 130,
      renderCell: (userName) => (
        <div>
          <p>{userName.value}</p>
        </div>
      ),
      flex: 1,
    },
    {
      field: "email",
      valueGetter: (params) => params.row.accountInfo.email,
      renderHeader: () => {
        return (
          <Typography variant="caption" color="primary">
            <FormattedMessage id="EmployeeScreen.table.emailHeader" />
          </Typography>
        );
      },
      width: 300,
      renderCell: (email) => <p>{email.value}</p>,
      flex: 2,
    },
    {
      field: "createUserButton",
      width: 175,
      filterable: false,
      hideable: false,
      pinnable: false,
      resizable: false,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      renderHeader: () => {
        if (checkForPrivilege(PrivilegeEnum.CAN_CREATE_USER)) {
          return (
            <LoadingButton
              loading={createUserDialogActive}
              loadingPosition="start"
              variant="contained"
              startIcon={<PersonAdd />}
              data-testid="CreateUserButton"
              onClick={() => setCreateUserDialogActive(true)}>
              <FormattedMessage id="EmployeeScreen.table.createUserButton" />
            </LoadingButton>
          );
        } else {
          return null;
        }
      },
    },
  ];

  return (
    <Container maxWidth="md" sx={{ marginY: 2 }} style={{ flex: 1 }}>
      {users && (
        <DataGrid
          key={pageSize}
          autoHeight={true}
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
          rows={users}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 40]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          checkboxSelection={false}
          disableSelectionOnClick
          onRowClick={(params: GridRowParams) => {
            history.push({ pathname: "/users/" + params.row.id, state: { user: users } });
          }}
        />
      )}
      <EmployeeCreateModal isCreateUserDialogActive={createUserDialogActive} />
    </Container>
  );
};

export default EmployeeScreen;
