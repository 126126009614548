import HttpStatusCode from "../utils/HttpStatusCode";
import { UnauthorizedError, BadRequestError, NotFoundError, NoPermissinError, ConflictError } from "../utils/error";

export function statusCodeError(statusCode: number) {
  // If request not successfull
  if (statusCode === HttpStatusCode.BAD_REQUEST) {
    throw new BadRequestError("error");
  }
  if (statusCode === HttpStatusCode.UNAUTHORIZED) {
    throw new UnauthorizedError("error");
  }
  if (statusCode === HttpStatusCode.NOT_FOUND) {
    throw new NotFoundError("error");
  }
  if (statusCode === HttpStatusCode.FORBIDDEN) {
    throw new NoPermissinError("error");
  }
  if (statusCode === HttpStatusCode.CONFLICT) {
    throw new ConflictError("error");
  }
}
