import Axios, { AxiosRequestConfig } from "axios";
import CookieParser from "../utils/CookieParser";
import { statusCodeError } from "./statusCodeError";

const API_URL = process.env.REACT_APP_SERVER;

function authRequestInterceptor(config: AxiosRequestConfig) {
  const cookie = CookieParser.parseCookie();
  if (cookie?.tokens) {
    config.headers.authorization = `${cookie.tokens?.accessToken ?? ""}`;
  }

  config.headers.Accept = "application/json;charset=UTF-8";
  return config;
}

export const axiosClient = Axios.create({
  baseURL: API_URL,
});

axiosClient.defaults.withCredentials = true;

axiosClient.interceptors.request.use(authRequestInterceptor);
axiosClient.interceptors.response.use(
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  (response: any) => {
    statusCodeError(response.status);
    return response;
  },
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  (error: any) => {
    statusCodeError(error.response.status);
    return Promise.reject(error);
  },
);
