import { CreateCustomerDTO, Customer } from ".";
import { axiosClient } from "../../lib/axios";

class CustomerClient {
  static async getAllCustomers(): Promise<Customer[]> {
    const response = await axiosClient.get("/customer");

    return response.data;
  }

  static async getCustomer(customerId: string): Promise<Customer> {
    const response = await axiosClient.get(`/customer/${customerId}`);

    return response.data;
  }

  static async createCustomer(newCustomer: CreateCustomerDTO): Promise<Customer> {
    const response = await axiosClient.post("/customer", newCustomer);

    return response.data;
  }
}

export default CustomerClient;
