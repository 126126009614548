import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { FC, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Project } from "../../clients/project";
import { ProjectContext } from "../../contexts/ProjectContext";

interface ProjectDeleteDialogProps {
  openDialog: boolean;
  handleClose: () => void;
  project: Project;
}

const ProjectDeleteDialog: FC<ProjectDeleteDialogProps> = ({ openDialog, handleClose, project }: ProjectDeleteDialogProps) => {
  const { refreshProject, submitProjectDelete } = useContext(ProjectContext);

  return (
    <Dialog open={openDialog} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">{<FormattedMessage id="ProjectScreen.DeleteDialog.Title" />}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormattedMessage id="ProjectScreen.DeleteDialog.Message" />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage id="ProjectScreen.DeleteDialog.Cancel" />
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={() => {
            submitProjectDelete(project.id);
            setTimeout(() => {
              refreshProject();
            }, 500);
            handleClose();
          }}
          autoFocus>
          <FormattedMessage id="ProjectScreen.DeleteDialog.Delete" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectDeleteDialog;
