import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { Salary } from "../clients/salary";
import SalaryClient from "../clients/salary/SalaryClient";
import { BadRequestError, NoPermissinError, NotFoundError } from "../utils/error";
import { NotificationContext } from "./NotificationContext";

interface SalaryType {
  salaries: Salary[];
  refetchSalaries: () => void;
  setSalaries: Dispatch<SetStateAction<Salary[]>>;
  createSalary: (userId: string, salaries: Salary) => void;
  handleSalaryUpdate: (salaries: Salary) => void;
  submitSalaryDelete: (id: string) => void;
}

export const SalaryContext = createContext({} as SalaryType);

const SalaryProvider: React.FC = ({ children }) => {
  const [salaries, setSalaries] = useState<Salary[]>([]);
  const { setNotification } = useContext(NotificationContext);
  const { userId } = useParams<{ userId: string }>();
  const intl = useIntl();

  const refetchSalaries = () => {
    getSalaries();
  };

  /**
   * Get Salaries
   */
  const getSalaries = () => {
    SalaryClient.getSalaries(userId)
      .then((salaries: Salary[]) => {
        setSalaries(salaries);
      })
      .catch((error) => {
        if (error instanceof BadRequestError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
        }
        if (error instanceof NoPermissinError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "SalaryContext.noPermission.message" }) });
        }
        if (error instanceof NotFoundError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "SalaryContext.SalaryNotFound.message" }) });
        }
      });
  };

  useEffect(() => {
    getSalaries();
    // eslint-disable-next-line
  }, []);

  /**
   * Create Salary
   * @param userId
   * @param salary
   */
  const createSalary = (userId: string, salary: Salary) => {
    SalaryClient.createSalary(userId, salary)
      .then((response: Salary) => {
        setSalaries([...salaries, response]);
        setNotification({ type: "success", message: intl.formatMessage({ id: "SalaryContext.CreateSalary.message" }) });
      })
      .catch((error) => {
        if (error instanceof BadRequestError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
        }
        if (error instanceof NoPermissinError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "SalaryContext.noPermission.message" }) });
        }
      });
  };

  /**
   * Update Salary
   * @param salary
   */
  const updateSalary = (salary: Salary) => {
    SalaryClient.updateSalary(salary)
      .then((response: Salary) => {
        const tmpSalaries = salaries.filter((salary) => {
          return salary.id !== response.id;
        });
        setSalaries([...tmpSalaries, response]);
        setNotification({ type: "success", message: intl.formatMessage({ id: "SalaryContext.SalaryChanged.message" }) });
      })
      .catch((error) => {
        if (error instanceof BadRequestError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
        }
        if (error instanceof NoPermissinError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "SalaryContext.noPermission.message" }) });
        }
        if (error instanceof NotFoundError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "SalaryContext.SalaryNotFound.message" }) });
        }
      });
  };

  const handleSalaryUpdate = (salary: Salary) => {
    updateSalary(salary);
  };

  /**
   * Delete Salary
   * @param salaryId
   */
  const deleteSalary = (salaryId: string) => {
    SalaryClient.deleteSalary(salaryId)
      .then(() => {
        const filtertSalaries = salaries.filter((salary) => {
          return salary.id !== salaryId;
        });
        setSalaries([...filtertSalaries]);
        setNotification({ type: "success", message: intl.formatMessage({ id: "SalaryContext.SalaryDelete.message" }) });
      })
      .catch((error) => {
        if (error instanceof BadRequestError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
        }
        if (error instanceof NoPermissinError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "SalaryContext.noPermission.message" }) });
        }
        if (error instanceof NotFoundError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "SalaryContext.SalaryNotFound.message" }) });
        }
      });
  };

  const submitSalaryDelete = (salaryId: string) => {
    deleteSalary(salaryId);
  };

  const data = {
    salaries,
    refetchSalaries,
    setSalaries,
    createSalary,
    handleSalaryUpdate,
    submitSalaryDelete,
  };
  return <SalaryContext.Provider value={data}> {children} </SalaryContext.Provider>;
};

export default SalaryProvider;
