import { Box, Container, Tab, Tabs, Typography } from "@mui/material";
import React, { FC, useContext, useState } from "react";
import { ProjectContext } from "../../contexts/ProjectContext";
import ProjectTable from "./ProjectTable";
import { FormattedMessage } from "react-intl";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ProjectScreen: FC = () => {
  const [isExternal, setIsExternal] = useState(0);
  const { projects } = useContext(ProjectContext);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setIsExternal(newValue);
  };

  return (
    <Container maxWidth="md" sx={{ marginY: 2 }} style={{ flex: 1 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs textColor="inherit" indicatorColor="primary" value={isExternal} onChange={handleChange}>
          <Tab label={<FormattedMessage id="ProjectScreen.Tabs.Internal" />} />
          <Tab label={<FormattedMessage id="ProjectScreen.Tabs.External" />} />
        </Tabs>
      </Box>
      <TabPanel value={isExternal} index={0}>
        {projects.length >= 0 && <ProjectTable projects={projects} />}
      </TabPanel>
      <TabPanel value={isExternal} index={1}>
        {projects.length >= 0 && <ProjectTable projects={projects} isExternal />}
      </TabPanel>
    </Container>
  );
};

export default ProjectScreen;
