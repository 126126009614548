import { AccountBox, AddModerator, FolderSpecial, People, List } from "@mui/icons-material";
import React, { FC, ReactElement } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Navigation from "./components/Navigation/Navigation";
import ProfileScreen from "./components/ProfileScreen/ProfileScreen";
import EmployeeScreen from "./components/EmployeeScreen/EmployeeScreen";
import RolesScreen from "./components/RolesScreen/RolesScreen";
import EmployeeProfileScreen from "./components/EmployeeScreen/EmployeeProfileScreen/EmployeeProfileScreen";
import ProjectScreen from "./components/ProjectScreen/ProjectScreen";
import LoginScreen from "./components/LoginScreen/LoginScreen";
import { useCookies } from "react-cookie";
import ProjectProvider from "./contexts/ProjectContext";
import RoleProvider from "./contexts/RoleContext";
import NotificationProvider from "./contexts/NotificationContext";

import "react-toastify/dist/ReactToastify.css";
import UserProvider from "./contexts/UserContext";
import ProjectDetailScreen from "./components/ProjectScreen/ProjectDetailScreen/ProjectDetailScreen";
import PrivilegeProvider from "./contexts/PrivilegeContext";
import { PrivilegeEnum } from "./components/RolesScreen/PrivilegeEnum";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import deLocale from "date-fns/locale/de";
import ProjectKeyinformation from "./components/ProjectScreen/ProjectDetailScreen/ProjectKeyinformation/ProjectKeyinformation";
import CustomersScreen from "./components/CustomersScreen/CustomersScreen";
import CustomerProvider from "./contexts/CustomerContext";
import CustomerDetailScreen from "./components/CustomersScreen/CustomerDetailScreen/CustomerDetailScreen";

export interface RouteInfo {
  path: string;
  title: string;
  component: ReactElement;
  privilege?: PrivilegeEnum;
  drawerIcon?: ReactElement;
}

export const routesNavBar: RouteInfo[] = [
  {
    path: "/users",
    title: "EmployeeScreen.title",
    privilege: PrivilegeEnum.CAN_READ_USER,
    component: (
      <UserProvider>
        <EmployeeScreen />
      </UserProvider>
    ),
    drawerIcon: <People />,
  },
  {
    path: "/roles",
    title: "RolesScreen.title",
    privilege: PrivilegeEnum.CAN_READ_ROLE,

    component: (
      <RoleProvider>
        <RolesScreen />
      </RoleProvider>
    ),
    drawerIcon: <AddModerator />,
  },
  {
    path: "/project",
    title: "ProjectScreen.Table.title",
    privilege: PrivilegeEnum.CAN_READ_PROJECT,

    component: (
      <ProjectProvider>
        <ProjectScreen />
      </ProjectProvider>
    ),
    drawerIcon: <FolderSpecial />,
  },
  {
    path: "/customer",
    title: "Customers.Table.title",
    privilege: PrivilegeEnum.CAN_READ_CUSTOMER,

    component: (
      <CustomerProvider>
        <CustomersScreen />
      </CustomerProvider>
    ),
    drawerIcon: <List />,
  },
];

export const routes: RouteInfo[] = [
  {
    path: "/users/:userId",
    title: "EmployeeProfileScreen.title",
    component: (
      <UserProvider>
        <EmployeeProfileScreen />
      </UserProvider>
    ),
  },
  {
    path: "/project/:projectId",
    title: "ProjectDetailScreen.title",
    component: (
      <ProjectProvider>
        <UserProvider>
          <ProjectDetailScreen />
        </UserProvider>
      </ProjectProvider>
    ),
  },
  {
    path: "/customer/:customerId",
    title: "CustomerDetailScreen.title",
    component: (
      <CustomerProvider>
        <UserProvider>
          <CustomerDetailScreen />
        </UserProvider>
      </CustomerProvider>
    ),
  },
  {
    path: "/projectkeyinformation/:projectId/keyinformation",
    title: "ProjectKeyinformation.Table.title",
    privilege: PrivilegeEnum.CAN_READ_KEYINFORMATION,
    component: (
      <ProjectProvider>
        <UserProvider>
          <ProjectKeyinformation />
        </UserProvider>
      </ProjectProvider>
    ),
  },
  {
    path: "/profile",
    title: "AccountScreen.title",
    component: <ProfileScreen />,
    drawerIcon: <AccountBox />,
  },
  ...routesNavBar,
];

const App: FC = () => {
  const [cookie] = useCookies(["userData"]);
  // tempfix for missing loging
  if (!cookie.userData?.tokens.accessToken) return <LoginScreen />;
  else {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
        <BrowserRouter>
          <PrivilegeProvider>
            <NotificationProvider>
              <Navigation />
              <Switch>
                {routes.map((route: RouteInfo) => (
                  <Route path={route.path} key={route.path}>
                    {route.component}
                  </Route>
                ))}
                <Redirect from="/" to="profile" />
              </Switch>
            </NotificationProvider>
          </PrivilegeProvider>
        </BrowserRouter>
      </LocalizationProvider>
    );
  }
};

export default App;
