import { axiosClient } from "../../lib/axios";
import { CreateProjectDTO, Project, UpdateProjectDTO } from ".";
import { AxiosResponse } from "axios";

class ProjectClient {
  static async getAllProjects(): Promise<Project[]> {
    const response = await axiosClient.get("/project");

    return response.data;
  }

  static async getProject(projectId: string): Promise<Project> {
    const response = await axiosClient.get(`/project/${projectId}`);

    return response.data;
  }

  static async createProject(title: CreateProjectDTO): Promise<Project> {
    const response = await axiosClient.post("/project", title);

    return response.data;
  }

  static async updateProject(project: UpdateProjectDTO): Promise<Project> {
    const response: AxiosResponse<Project> = await axiosClient.put(`/project/${project.id}`, project);

    return response.data;
  }

  static async deleteProject(projectId: string): Promise<Project> {
    const response = await axiosClient.delete(`/project/${projectId}`);

    return response.data;
  }
}

export default ProjectClient;
