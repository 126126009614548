import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { FC, useCallback, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@mui/lab";
import { UpdateProjectDTO } from "../../../clients/project";

interface EditProjectInfoModalProps {
  openDialog: boolean;
  setOpenDialog: (editProjectInfoDialog: boolean) => void;
}

const EditProjectInfoModal: FC<EditProjectInfoModalProps> = ({ openDialog, setOpenDialog }: EditProjectInfoModalProps) => {
  const { register, handleSubmit, control, getValues } = useForm({ mode: "onBlur", reValidateMode: "onChange" });
  const { currentProject, updateProject } = useContext(ProjectContext);

  const handleClose = useCallback(() => {
    setOpenDialog(false);
  }, [setOpenDialog]);

  const submitHandler = () => {
    const updatedProject: UpdateProjectDTO = {
      id: currentProject.id,
      title: getValues("title"),
      customer: getValues("customer"),
      projectStart: getValues("projectStart"),
      budget: getValues("budget"),
      status: getValues("status") ?? "UNKNOWN",
      statusDetails: getValues("statusDetails"),
      contactPersons: currentProject.contactPersons.map((contactPerson) => contactPerson.id),
      projectEmployees: currentProject.projectEmployees,
      isExternal: getValues("isExternal"),
    };
    updateProject(updatedProject);
    handleClose();
  };

  return (
    <Dialog maxWidth="md" fullWidth open={openDialog} onClose={handleClose} data-testid="EditProjectInfoModal">
      <DialogTitle>
        <FormattedMessage id="ProjectDetailScreen.editProjectInfoDialog.title" />
      </DialogTitle>
      <DialogContent dividers sx={{ px: 3 }}>
        <Box component="form" onSubmit={handleSubmit(submitHandler)}>
          <Grid container columnSpacing={2} rowSpacing={4}>
            <Grid item md={3}>
              <TextField
                {...register("title")}
                data-testid="CustomerTextfield"
                variant="standard"
                fullWidth
                defaultValue={currentProject.title}
                label={<FormattedMessage id="ProjectDetailScreen.editProjectInfoDialog.projectTitle" />}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                {...register("customer")}
                variant="standard"
                fullWidth
                defaultValue={currentProject.customer}
                label={<FormattedMessage id="ProjectDetailScreen.editProjectInfoDialog.customer" />}
              />
            </Grid>
            <Grid item md={3}>
              <TextField
                {...register("budget")}
                variant="standard"
                fullWidth
                type="number"
                defaultValue={currentProject.budget}
                label={<FormattedMessage id="ProjectDetailScreen.editProjectInfoDialog.budget" />}
                InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 16 }}>
            <Grid item md={3}>
              <Controller
                name="projectStart"
                rules={{
                  validate: (value) => {
                    return !isNaN(Date.parse(value));
                  },
                }}
                defaultValue={currentProject.projectStart}
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <DatePicker
                    mask=""
                    label={<FormattedMessage id="ProjectDetailScreen.editProjectInfoDialog.projectStart" />}
                    inputFormat="dd.MM.yyyy"
                    value={value}
                    onChange={onChange}
                    renderInput={(params) => (
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <TextField name="projectStart" fullWidth variant="standard" {...params} />{" "}
                        <Collapse in={!!error}>
                          <FormHelperText sx={{ fontSize: "9pt" }} error>
                            <FormattedMessage id="ProjectDetailScreen.editProjectInfoDialog.activeFrom.error" />
                          </FormHelperText>
                        </Collapse>
                      </Box>
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item md={3}>
              <TextField
                {...register("statusDetails")}
                variant="standard"
                fullWidth
                defaultValue={currentProject.statusDetails}
                label={<FormattedMessage id="ProjectDetailScreen.editProjectInfoDialog.statusDetails" />}
              />
            </Grid>
            <Grid border={"hidden"} item md={4}>
              <FormControl fullWidth variant="standard">
                <InputLabel style={{ marginBottom: 8 }} id="ProjectDetailScreen.editProjectInfoDialog.status.InputLabel">
                  <FormattedMessage id="ProjectDetailScreen.editProjectInfoDialog.status.selectLabel" />
                </InputLabel>
                <Select
                  sx={{ boxShadow: "none", ".MuiOutlinedInput-notchedOutline": { border: 0 } }}
                  labelId="currentProjectStatusSelectLabel"
                  {...register("status")}
                  defaultValue={currentProject.status ?? "UNKNOWN"}>
                  <MenuItem value={"DEVELOPMENT"}>
                    <FormattedMessage id="ProjectDetailScreen.editProjectInfoDialog.status.development" />
                  </MenuItem>
                  <MenuItem value={"MAINTENANCE"}>
                    <FormattedMessage id="ProjectDetailScreen.editProjectInfoDialog.status.maintenance" />
                  </MenuItem>
                  <MenuItem value={"PAUSED"}>
                    <FormattedMessage id="ProjectDetailScreen.editProjectInfoDialog.status.paused" />
                  </MenuItem>
                  <MenuItem value={"FINISHED"}>
                    <FormattedMessage id="ProjectDetailScreen.editProjectInfoDialog.status.finished" />
                  </MenuItem>
                  <MenuItem value={"UNKNOWN"}>
                    <FormattedMessage id="ProjectDetailScreen.editProjectInfoDialog.status.unknown" />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid border={"hidden"}>
              <Controller
                name={"isExternal"}
                control={control}
                defaultValue={currentProject.isExternal}
                render={({ field: { onChange, value } }) => (
                  <Box>
                    <FormControlLabel
                      value="isExternal"
                      control={
                        <Switch
                          checked={value}
                          onChange={onChange}
                          sx={{ ml: 1 }}
                          size="medium"
                          data-testid={`ActiveSwitch-${currentProject.isExternal}`}
                        />
                      }
                      label={
                        <FormHelperText sx={{ fontSize: "9pt", margin: "0px", marginTop: "12px" }}>
                          <FormattedMessage id="ProjectDetailScreen.editProjectInfoDialog.ExternalProject" />
                        </FormHelperText>
                      }
                      labelPlacement="top"
                    />
                  </Box>
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          <FormattedMessage id="ProjectDetailScreen.editProjectInfoDialog.cancelButton" />
        </Button>
        <Button color="primary" variant="contained" onClick={handleSubmit(submitHandler)}>
          <FormattedMessage id="ProjectDetailScreen.editProjectInfoDialog.updateButton" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditProjectInfoModal;
