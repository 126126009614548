import { Salary } from ".";
import { axiosClient } from "../../lib/axios";

class SalaryClient {
  static async getSalaries(userId: string): Promise<Salary[]> {
    const response = await axiosClient.get(`/salary/${userId}`);

    return response.data;
  }

  static async createSalary(userId: string, salary: Salary): Promise<Salary> {
    const response = await axiosClient.post(`/salary/${userId}`, salary);

    return response.data;
  }

  static async updateSalary(salary: Salary): Promise<Salary> {
    const response = await axiosClient.put(`/salary/${salary.id}`, salary);

    return response.data;
  }

  static async deleteSalary(salaryId: string): Promise<Salary> {
    const response = await axiosClient.delete(`/salary/${salaryId}`);

    return response.data;
  }
}

export default SalaryClient;
