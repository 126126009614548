import { CreateTimesheetDTO, Keyinformation, TimesheetDTO } from ".";
import { axiosClient } from "../../lib/axios";

class ProjectKeyinformationClient {
  static async getProjectKeyinformation(projectId: string): Promise<Keyinformation> {
    const response = await axiosClient.get(`/project/${projectId}/keyinformation`);

    return response.data;
  }

  static async updateProjectKeyinformation(projectId: string, keyinformation: Keyinformation): Promise<Keyinformation> {
    const response = await axiosClient.put(`/project/${projectId}/keyinformation`, keyinformation);

    return response.data;
  }

  static async addTimesheetToProject(projectId: string, timesheet: CreateTimesheetDTO): Promise<TimesheetDTO> {
    const response = await axiosClient.post(`/project/${projectId}/timesheet`, timesheet);
    return response.data;
  }

  static async deleteTimesheet(projectId: string, timesheetId: string): Promise<void> {
    const response = await axiosClient.delete(`/project/${projectId}/timesheet/${timesheetId}`);
    return response.data;
  }
}

export default ProjectKeyinformationClient;
