import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import React, { FC, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CreateCustomerDTO } from "../../clients/customer/.";
import { CustomerContext } from "../../contexts/CustomerContext";

interface CustomerCreateDialogProps {
  openDialog: boolean;
  handleClose: () => void;
}

const MIN_CHAR_LENGTH = 3;

const CustomerCreateDialog: FC<CustomerCreateDialogProps> = ({ openDialog, handleClose }: CustomerCreateDialogProps) => {
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState(false);
  const [pipeDriveError, setPipeDriveError] = useState(false);
  const [pipeDriveUrl, setPipeDriveUrl] = useState<string>("");
  const { createCustomer } = useContext(CustomerContext);

  const handleKeyUp = (event: React.KeyboardEvent<HTMLElement>) => {
    switch (event.key) {
      case "Enter":
        confirmCreateCustomer();
        break;
      case "Escape":
        handleClose();
        break;
      default:
        break;
    }
  };

  const confirmCreateCustomer = () => {
    if (!nameError && !pipeDriveError) {
      const newCustomer: CreateCustomerDTO = {
        name: name,
        pipedriveUrl: pipeDriveUrl,
      };
      createCustomer(newCustomer);

      handleClose();
    }
  };

  const validateName = (value: string) => {
    if (value.length < MIN_CHAR_LENGTH) {
      setNameError(true);
      return false;
    }
    setNameError(false);
    return true;
  };

  const validatePipeDriveUrl = (value: string) => {
    if (!!!value) {
      setPipeDriveError(true);
      return false;
    }
    setPipeDriveError(false);
    return true;
  };

  return (
    <Dialog maxWidth="xs" fullWidth open={openDialog} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">{<FormattedMessage id="CustomerScreen.CreateDialog.Title" />}</DialogTitle>
      <DialogContent onKeyUp={handleKeyUp}>
        <TextField
          autoFocus
          fullWidth
          label={<FormattedMessage id="CustomerScreen.CreateDialog.CustomerName" />}
          size="small"
          variant="standard"
          onChange={(event) => {
            if (validateName(event.target.value)) {
              setName(event.target.value);
            }
          }}
        />
        {nameError && (
          <Typography sx={{ fontSize: "9pt", color: "red" }}>
            <FormattedMessage id="CustomerScreen.CreateDialog.ErrorName" />
          </Typography>
        )}
        <TextField
          fullWidth
          label={<FormattedMessage id="CustomerScreen.CreateDialog.PipeDriveLink" />}
          size="small"
          variant="standard"
          onChange={(event) => {
            if (validatePipeDriveUrl(event.target.value)) {
              setPipeDriveUrl(event.target.value);
            }
          }}
        />
        {pipeDriveError && (
          <Typography sx={{ fontSize: "9pt", color: "red" }}>
            <FormattedMessage id="CustomerScreen.CreateDialog.ErrorPipeDriveLink" />
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          <FormattedMessage id="CustomerScreen.CreateDialog.Cancel" />
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => confirmCreateCustomer()}
          disabled={name.length === 0 || pipeDriveUrl.length === 0 || nameError || pipeDriveError}>
          <FormattedMessage id="CustomerScreen.CreateDialog.Create" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default CustomerCreateDialog;
