import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import CustomerClient from "../clients/customer/customerClient";
import { CreateCustomerDTO, Customer } from "../clients/customer/";
import { NotificationContext } from "./NotificationContext";
import { BadRequestError, ConflictError, NoPermissinError, NotFoundError } from "../utils/error";

interface CustomerType {
  customers: Customer[];
  currentCustomer: Customer;
  setCustomers: Dispatch<SetStateAction<Customer[]>>;
  setCurrentCustomer: (customer: Customer) => void;
  createCustomer: (newCustomer: CreateCustomerDTO) => void;
}

export const CustomerContext = createContext({} as CustomerType);

const CustomerProvider: React.FC = ({ children }) => {
  const { customerId } = useParams<{ customerId: string }>();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const { setNotification } = useContext(NotificationContext);

  const [currentCustomer, setCurrentCustomer] = useState<Customer>({
    id: "",
    name: "",
    companies: [],
    email: "",
    phoneMobile: "",
    phoneWork: "",
    pipedriveUrl: "",
    comment: "",
  });

  const intl = useIntl();

  /**
   * Get all Customers
   */
  const getAllCustomers = () => {
    CustomerClient.getAllCustomers()
      .then((customers) => {
        setCustomers(customers);
      })
      .catch((error) => {
        if (error instanceof BadRequestError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
        }
        if (error instanceof NoPermissinError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "CustomerContext.noPermission.message" }) });
        }
        if (error instanceof NotFoundError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "CustomerContext.CustomerNotFound.message" }) });
        }
      });
  };

  useEffect(() => {
    getAllCustomers();
    if (customerId !== undefined) {
      getCustomer();
    }
    // eslint-disable-next-line
  }, [setNotification, intl]);

  /**
   * Get a Customer
   */
  const getCustomer = () => {
    CustomerClient.getCustomer(customerId)
      .then((customer) => {
        setCurrentCustomer(customer);
      })
      .catch((error) => {
        if (error instanceof BadRequestError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
        }
        if (error instanceof NoPermissinError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "CustomerContext.noPermission.message" }) });
        }
        if (error instanceof NotFoundError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "CustomerContext.CustomerNotFound.message" }) });
        }
      });
  };

  /**
   * Create customer
   * @param newCustomer
   */
  const createCustomer = (newCustomer: CreateCustomerDTO) => {
    CustomerClient.createCustomer(newCustomer)
      .then((response: Customer) => {
        setCustomers([...customers, response]);
        setNotification({ type: "success", message: intl.formatMessage({ id: "CustomerContext.CreateCustomer.message" }) });
      })
      .catch((error) => {
        if (error instanceof BadRequestError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
        }
        if (error instanceof ConflictError) {
          setNotification({ type: "error", message: intl.formatMessage({ id: "CustomerContext.CustomerExists.message" }) });
        }
      });
  };

  const data = {
    customers,
    currentCustomer,
    setCustomers,
    setCurrentCustomer,
    createCustomer,
  };

  return <CustomerContext.Provider value={data}>{children}</CustomerContext.Provider>;
};

export default CustomerProvider;
