import { Add } from "@mui/icons-material";
import { Button, Card, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { PrivilegeContext } from "../../contexts/PrivilegeContext";
import { PrivilegeEnum } from "../RolesScreen/PrivilegeEnum";
import CustomerCreateDialog from "./CustomerCreateDialog";
import { useHistory } from "react-router-dom";
import { Customer } from "../../clients/customer/.";
import { CustomerContext } from "../../contexts/CustomerContext";

const CustomersScreen = () => {
  const { checkForPrivilege } = useContext(PrivilegeContext);
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
  const history = useHistory();
  const { customers } = useContext(CustomerContext);

  return (
    <>
      <Container maxWidth="md" sx={{ marginY: 2 }}>
        <TableContainer component={Card}>
          <Table sx={{ minWidth: 650 }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ backgroundColor: grey[300] }}>
                  <FormattedMessage id="Customers.Table.TableHeader.CustomerName" />
                </TableCell>
                <TableCell sx={{ backgroundColor: grey[300], py: 0 }} align="right">
                  {checkForPrivilege(PrivilegeEnum.CAN_CREATE_CUSTOMER) ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setOpenCreateDialog(true)}
                      startIcon={<Add />}
                      data-testid="CreateProjectButton">
                      <FormattedMessage id="Customer.Table.TableHeader.CreateCustomer" />
                    </Button>
                  ) : null}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((customer: Customer) => (
                <TableRow
                  onClick={() => {
                    history.push({ pathname: `/customer/${customer.id}` });
                  }}
                  hover
                  key={customer.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell size="small" component="th" scope="row">
                    {customer.name}
                  </TableCell>
                  <TableCell size="small"></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <CustomerCreateDialog
        openDialog={openCreateDialog}
        handleClose={() => {
          setOpenCreateDialog(false);
          history.replace("/customer");
        }}
      />
    </>
  );
};

export default CustomersScreen;
