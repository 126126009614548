import {
  Avatar,
  Box,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { AttachFile, Clear, Close, Delete, Description, Download, Inbox, MoreHoriz, Send } from "@mui/icons-material";
import { ChangeEvent, FC, useRef, useState, MouseEvent, useContext, useEffect } from "react";
import { CreateTimesheetDTO, ProjectEmployee, TimesheetDTO } from "../../../../clients/project";
import { ProjectContext } from "../../../../contexts/ProjectContext";
import { grey } from "@mui/material/colors";
import theme from "../../../../assets/theming/theme";
import { FormattedMessage } from "react-intl";
import { DatePicker, LoadingButton } from "@mui/lab";

interface TimesheetModalProps {
  openDialog: boolean;
  setOpenDialog: (timesheetDialog: boolean) => void;
  projectEmployee: ProjectEmployee;
  timesheetsDto: TimesheetDTO[];
}

const TimesheetModal: FC<TimesheetModalProps> = ({ openDialog, setOpenDialog, projectEmployee, timesheetsDto }: TimesheetModalProps) => {
  const { addTimesheetToProject, deleteTimesheetFromProject } = useContext(ProjectContext);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [loading, setLoading] = useState<boolean>(false);
  const [timeSpan, setTimeSpan] = useState<string | null>(new Date().toDateString());
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [timesheets, setTimesheets] = useState<TimesheetDTO[]>(timesheetsDto ?? []);

  useEffect(() => {
    setTimesheets(timesheetsDto);
  }, [timesheetsDto]);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFileInputClick = () => {
    inputRef.current?.click();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    setSelectedFile(event.target.files[0]);
  };

  const clearFileInput = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setSelectedFile(undefined);
  };

  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString() || "");
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileUpload = async () => {
    if (selectedFile && timeSpan) {
      setLoading(true);

      let fileAsString = await fileToBase64(selectedFile);
      fileAsString = fileAsString.replace("data:application/pdf;base64,", "");
      // dateFrom and dateTo have to be fixed
      const timesheet: CreateTimesheetDTO = {
        file: fileAsString,
        fileName: selectedFile.name,
        date: new Date(timeSpan),
        employee: projectEmployee.userDto.id,
      };

      addTimesheetToProject(timesheet);
      setLoading(false);
    }
  };

  const handleDeleteTimesheet = async (id: string) => {
    deleteTimesheetFromProject(id);
    setAnchorEl(null);
  };

  const handleDatePicker = (timespan: string) => {
    setTimeSpan(timespan);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedFile(undefined);
    setLoading(false);
  };

  return (
    <Dialog maxWidth="md" fullWidth open={openDialog} onClose={handleClose}>
      {projectEmployee ? (
        <>
          <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Avatar src={projectEmployee.userDto.accountInfo.picture} />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h5">{projectEmployee.userDto.accountInfo.username}</Typography>
                <Typography variant="body2">{projectEmployee.userDto.accountInfo.email}</Typography>
              </Box>
            </Box>
            <IconButton onClick={() => handleClose()}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
              <Tooltip title={selectedFile?.name} enterDelay={500}>
                <TextField
                  fullWidth
                  label={<FormattedMessage id="TimesheetModal.details.fileInput" />}
                  onClick={handleFileInputClick}
                  sx={{ "*": { cursor: "pointer" } }}
                  variant="standard"
                  size="small"
                  inputProps={{
                    readOnly: true,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ cursor: "pointer" }}>
                        <AttachFile fontSize="small" />
                      </InputAdornment>
                    ),
                    value: selectedFile ? selectedFile.name : "",
                    endAdornment: selectedFile ? (
                      <>
                        <InputAdornment position="start">
                          <IconButton onClick={clearFileInput} edge="end">
                            <Clear />
                          </IconButton>
                        </InputAdornment>
                      </>
                    ) : null,
                  }}
                />
              </Tooltip>
              <input type="file" accept="application/pdf" value={""} hidden ref={inputRef} onChange={handleFileChange} />

              <DatePicker
                value={timeSpan}
                onChange={(value) => {
                  if (value) {
                    handleDatePicker(value);
                  }
                }}
                views={["month", "year"]}
                label={<FormattedMessage id="TimesheetModal.details.timeSpanInput" />}
                inputFormat="MMMM yyyy"
                mask=""
                renderInput={(params: TextFieldProps) => (
                  <TextField sx={{ maxWidth: "175px", mb: "3px" }} fullWidth variant="standard" {...params} />
                )}
              />
              <LoadingButton
                loading={loading}
                onClick={handleFileUpload}
                disabled={!selectedFile || !timeSpan}
                sx={{ minWidth: "140px" }}
                endIcon={<Send />}
                variant="contained"
                color="primary">
                <FormattedMessage id="TimesheetModal.details.uploadTimesheetButton" />
              </LoadingButton>
            </Box>
            <TableContainer component={Card} sx={{ maxHeight: "310px", overflow: "auto" }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: grey[300] }}>
                      <FormattedMessage id="TimesheetModal.details.table.file" />
                    </TableCell>
                    <TableCell sx={{ minWidth: "100px", backgroundColor: grey[300] }} colSpan={2}>
                      <FormattedMessage id="TimesheetModal.table.timeSpan" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {timesheets.length > 0 ? (
                    timesheets.map(({ id, fileName, date, file }) => (
                      <TableRow
                        key={id}
                        hover
                        sx={{
                          "&:hover [data-testid='TimesheetEditButton']": { color: theme.palette.primary.main },
                          "&:hover [data-testid='TimesheetDownloadButton']": { color: theme.palette.primary.main },
                          "&:hover [data-testid='TimesheetDeleteButton']": { color: theme.palette.error.main },
                        }}>
                        <TableCell>
                          <Description fontSize="small" />
                          <Tooltip enterDelay={500} title={fileName}>
                            <Typography variant="body2" sx={{ maxWidth: "400px" }} noWrap>
                              {fileName}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {new Date(date).toLocaleString("de-DE", { year: "numeric", month: "long" })}
                          </Typography>
                        </TableCell>
                        <TableCell align="right" sx={{ py: 0 }}>
                          <IconButton
                            aria-controls="more-menu"
                            aria-haspopup="true"
                            onClick={(event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}>
                            <MoreHoriz />
                          </IconButton>
                          <Menu
                            id="more-menu"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}>
                            <Link
                              sx={{ display: "flex", alignItems: "center", gap: 1 }}
                              style={{ color: "inherit", textDecoration: "inherit" }}
                              href={file.url}
                              target="_blank"
                              download>
                              <MenuItem sx={{ "&&:hover *": { color: theme.palette.primary.main } }}>
                                <ListItemIcon>
                                  <Download />
                                </ListItemIcon>
                                <Typography>Herunterladen</Typography>
                              </MenuItem>
                            </Link>

                            <Divider />
                            <MenuItem sx={{ "&&:hover *": { color: theme.palette.error.main } }} onClick={() => handleDeleteTimesheet(id)}>
                              <ListItemIcon>
                                <Delete />
                              </ListItemIcon>
                              <Typography>Löschen</Typography>
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={2}>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <Inbox
                            color="action"
                            sx={{ mb: 2, width: 128, height: 128, p: 3, backgroundColor: "#e5e5e5", borderRadius: "50%" }}
                          />
                          <Typography color="action" variant="subtitle1">
                            <FormattedMessage id="TimesheetModal.details.table.emptyState" />
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </>
      ) : null}
    </Dialog>
  );
};

export default TimesheetModal;
